import { Hero, Media, BREAKPOINTS } from 'day8-react/build/new-components';
import PageLayout from '../layouts/page';

type ErrorType = {
  statusCode: number
  error: string
}
function Custom404({ statusCode, error }: ErrorType) {
  const errorGifs = [
    'https://media.giphy.com/media/KHPyJlogAoc64T16Of/giphy.gif',
    'https://media.giphy.com/media/ctXpBPDu0QCFbTXYqd/giphy.gif'
  ];
  const mobileErrorGifs = [
    'https://media.giphy.com/media/3ohjV5LQl37fGh73BC/giphy.gif'
  ];
  return (
    <PageLayout page={{ title: { rendered: '404' } }} signupHidden>
      <Media greaterThanOrEqual={BREAKPOINTS.SM}>
        <Hero
          bgImage={{
            alt: "Funny ski GIF",
            url: errorGifs[Math.floor(Math.random() * errorGifs.length)]
          }}
          title="Error 404"
          subtitle="Ooops, the page you were looking for doesn't seem to exist anymore."
          cta={{
            text: "Back to our home",
            link: "/"
          }}
          overlay
          fullHeight
          whiteFont
        />
      </Media>
      <Media lessThan={BREAKPOINTS.SM}>
        <Hero
          bgImage={{
            alt: "Funny ski GIF",
            url: mobileErrorGifs[
              Math.floor(Math.random() * mobileErrorGifs.length)
            ]
          }}
          title="Error 404"
          subtitle="Ooops, the page you were looking for doesn't seem to exist anymore."
          cta={{
            text: "Back to our home",
            link: "/"
          }}
          overlay
          fullHeight
          whiteFont
        />
      </Media>
    </PageLayout>
  );
}

export default Custom404;

